import i18next from 'i18next';

i18next.init(
    {
        lng: 'no',
        debug: true,
        resources: {
            no: {
                translation: {
                    "config": {
                        "lazer_scanner": "Aktiver laserscanner",
                        "column_scanner": "Aktiver søylescanner",
                        "api_dev": "Bruk test-API",
                        "save": "Lagre",
                        "save_success": "Konfig lagret"
                    },
                    "user": {
                        "login_form": {
                            "title": "Arrangør innlogging",
                            "email": "Epost",
                            "email_placeholder": "ola.normann@epost.no",
                            "password": "Passord",
                            "button": "Logg inn"
                        },
                        "logged_in_as": "Logget inn som",
                        "logout_confirm": "Vil du logge ut?"
                    },
                    "organizer": {
                        "select_organizer": {
                            "title": "Velg arrangør"
                        }
                    },
                    "event": {
                        "select_event": {
                            "title": "Velg event"
                        }
                    },
                    "event_entry_point": {
                        "select_event_entry_point": {
                            "title": "Velg inngang"
                        }
                    },
                    "menu": {
                        //"log_in": "Logg inn",
                        "log_out": "Logg ut",
                        "change_event": "Bytt event",
                        "config": "Konfig"
                    },
                    "ticket": {
                        "tabs": {
                            "scan": "Skann",
                            "search": "Søk"

                        },
                        "lazer_scanner": {
                            "start_text": "Skann en billett"
                        },
                        "column_scanner": {
                            "start_text": "Skann din billett",
                            "start_text2": "Scan your ticket"
                        },
                        "search_form": {
                            "today": "I dag",
                            "from_today": "F.o.m. i dag",
                            "placeholder": "Søk etter billetter",
                            "button": "Søk",
                            "errors": {
                                "empty_search_string": "Tast inn søkeord",
                                "no_search_result": "Fant ingen billetter"
                            }
                        },
                        "search_result": {
                            "email": "Epost",
                            "phone": "Tlf",
                            "order_date": "Ordre",
                            "event_date": "Event",
                            "ticket_category": "Billettype",
                            "ticket_reference": "Ref",
                            "ticket_deactivated": "Deaktivert",
                            "ticket_already_used": "Brukt",
                            "ticket_refunded": "Refundert",
                            "ticket_validate_button": "Valider",
                            "ticket_validate_ok": "Billett OK"
                        },
                        "ticket_validate": {
                            "no_match_for_ticket": "Ukjent billettkode",
                            "invalid_ticket": "Ugyldig billett",
                            "ticket_already_used": "Billetten er allerede brukt",
                            "ticket_used": "Den ble brukt",
                            "ticket_deactivated": "Billetten er deaktivert",
                            "ticket_refunded": "Billetten er refundert",
                            "validate_popup_close_button": "Lukk",
                            "tickets_admitted": "Inn",
                            "tickets_sold": "Solgt",
                            "tickets_total": "Tot"
                        }
                    },
                    "errors": {
                        "offline": "Kunne ikke koble til internet, prøv igjen når du har fått tilbake nettet.",
                        "generic": "Det oppstod en feil."
                    }
                }
            },
            en: {
                translation: {
                    "config": {
                        "lazer_scanner": "Activate lazer scanner",
                        "column_scanner": "Activate column scanner",
                        "api_dev": "Use development API",
                        "save": "Save",
                        "save_success": "Konfig lagret"
                    },
                    "user": {
                        "login_form": {
                            "title": "Organizer login",
                            "email": "Email",
                            "email_placeholder": "john.doe@email.com",
                            "password": "Password",
                            "button": "Log in"
                        },
                        "logged_in_as": "Logged in as",
                        "logout_confirm": "Do you want to logout?"
                    },
                    "organizer": {
                        "select_organizer": {
                            "title": "Select organizer"
                        }
                    },
                    "event": {
                        "select_event": {
                            "title": "Select event"
                        }
                    },
                    "event_entry_point": {
                        "select_event_entry_point": {
                            "title": "Select event entry point"
                        }
                    },
                    "menu": {
                        //"log_in": "Log in",
                        "log_out": "Log out",
                        "change_event": "Change event",
                        "config": "Config"
                    },
                    "ticket": {
                        "tabs": {
                            "scan": "Scan",
                            "search": "Search"

                        },
                        "lazer_scanner": {
                            "start_text": "Scan a ticket"
                        },
                        "column_scanner": {
                            "start_text": "Skann din billett",
                            "start_text2": "Scan your ticket"
                        },
                        "search_form": {
                            "today": "I dag",
                            "from_today": "F.o.m. i dag",
                            "placeholder": "Search for ticket",
                            "button": "Search",
                            "errors": {
                                "empty_search_string": "Type a search string",
                                "no_search_result": "No tickets found"
                            }
                        },
                        "search_result": {
                            "email": "Email",
                            "phone": "Tlf",
                            "order_date": "Order",
                            "event_date": "Event",
                            "ticket_category": "Type",
                            "ticket_reference": "Ref",
                            "ticket_deactivated": "Deactivated",
                            "ticket_already_used": "Used",
                            "ticket_refunded": "Refunded",
                            "ticket_validate_button": "Validate",
                            "ticket_validate_ok": "Ticket OK"
                        },
                        "ticket_validate": {
                            "no_match_for_ticket": "Unknown ticket code",
                            "invalid_ticket": "Ugyldig billett",
                            "ticket_already_used": "Ticket is already used",
                            "ticket_used": "Used",
                            "ticket_deactivated": "Ticket is deactivated",
                            "ticket_refunded": "Ticket is refunded",
                            "validate_popup_close_button": "Close",
                            "tickets_admitted": "In",
                            "tickets_sold": "Sold",
                            "tickets_total": "Tot"
                        }
                    },
                    "errors": {
                        "offline": "Kunne ikke koble til internet, prøv igjen når du har fått tilbake nettet.",
                        "generic": "Det oppstod en feil."
                    }
                }
            }
        }
    }
);

export default i18next;

