import React from 'react';
import {css} from '@emotion/core';
import {BarLoader} from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: #2163D3;
`;

interface State {
    loading: boolean
}

class LoadingSpinner extends React.Component <{}, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true
        }
    }

    render() {
        return (
            <div className="sweet-loading text-center">
                <BarLoader
                    css={override}
                    color={'#2163D3'}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default LoadingSpinner;