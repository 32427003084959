import * as React from 'react';
import {Button} from "reactstrap";
import {History} from 'history';
import LoadingSpinner from "./LoadingSpinner";
import {connect} from "react-redux";
import {selectOrganizer} from "../actions/actions";
import i18next from '../language/translation';
import Utils from "../utils/Utils";

interface State {
    organizers: [];
    isLoaded: boolean;
    errorMessage: string;
}

interface Props {
    history: History;
}

class SelectOrganizer extends React.Component <Props, State> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            errorMessage: '',
            isLoaded: false,
            organizers: []
        };
        this.selectOrganizerClick = this.selectOrganizerClick.bind(this);
    }

    public render() {
        const {errorMessage, isLoaded, organizers} = this.state;
        if (errorMessage) {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px", marginTop: "15px"}}>
                        <div className="card-box col-md-6 offset-md-3">
                            {errorMessage}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (!isLoaded) {
            return (
                <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group pt-4">
                    <LoadingSpinner/>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                        <h1 className="col-md-6 offset-md-3 pl-0 pt-4">
                            {i18next.t('organizer.select_organizer.title')}
                        </h1>
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group">
                            {organizers && organizers.map((organizer: any) => (
                                <Button key={organizer.organizer_id}
                                        className="list-group-item list-group-item-action organizer"
                                        value={organizer.organizer_id}
                                        onClick={this.selectOrganizerClick}>{organizer.organizer_name}
                                </Button>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    componentWillMount() {
        this.getOrganizers(localStorage.getItem('user_id'), localStorage.getItem('token'));
    }

    private getOrganizers(user_id: any, token: any) {
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizers',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.organizers !== undefined) {
                if (json.organizers.length == 1) {
                    this.selectOrganizer(localStorage.getItem('user_id'), json.organizers[0].organizer_id, localStorage.getItem('token'));
                } else {
                    this.setState({
                        isLoaded: true,
                        organizers: json.organizers
                    });
                }
            } else {
                this.setState({
                    isLoaded: true,
                    errorMessage: json.error
                });
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }

    selectOrganizerClick(event: any) {
        event.preventDefault();
        let organizerId = event.target.value;
        this.selectOrganizer(localStorage.getItem('user_id'), organizerId, localStorage.getItem('token'));
    }

    private selectOrganizer(user_id: any, organizer_id: any, token: any) {
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/select',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.token !== undefined && json.organizer_id) {
                localStorage.setItem('token', json.token);
                localStorage.setItem('organizer_id', json.organizer_id);
                localStorage.setItem('organizer_name', json.organizer_name);
                //sessionStorage.setItem('refresh', 'true');
                //store.dispatch(selectOrganizer(json.organizer_name));
                this.props.history.push("/user/organizer/select_event");
            } else {
                this.setState({errorMessage: json.error});
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }


}

/*const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(SelectOrganizer);*/

export default SelectOrganizer;