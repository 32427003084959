import React from 'react';
import Header from "./Header";
import Login from "./Login";
import SelectOrganizer from "./SelectOrganizer";
import SelectEvent from "./SelectEvent";
import TicketValidation from "./ticket/TicketValidation";
import {Container} from "reactstrap";
import {HashRouter, Route} from "react-router-dom";
import Footer from "./Footer";
import Config from "./Config";
import SelectEventEntryPoint from "./SelectEventEntryPoint";


interface Props {
}


const App: React.FC<Props> = (State, Props) => {

    const columnScanner = (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1');
    if (columnScanner) {
        // @ts-ignore
        document.querySelector('#body').style.backgroundColor = '#2b2b2b';
    }

    return (
        <div
            className={columnScanner ? "App column-scanner-container asd2" : "App"}
            id="content">
            <HashRouter>
                <Header {...Props} />
                <Container style={{paddingLeft: '0', paddingRight: '0'}}>
                    <Route path="/" exact component={Login} language={onlanguagechange}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/user/select_organizer" component={SelectOrganizer}/>
                    <Route path="/user/organizer/select_event" component={SelectEvent}/>
                    <Route path="/user/organizer/event/select_event_entry_point" component={SelectEventEntryPoint}/>
                    <Route path="/user/organizer/event/ticket" component={TicketValidation}/>
                    <Route path="/config" component={Config}/>
                </Container>
                <Footer/>
            </HashRouter>
            <div id="error-alert" className="error-alert"></div>
        </div>

    );

};

export default App as React.ComponentType<Props>;
