import i18next from "../language/translation";

class Utils {
    static parseJwt(token: string) {
        try {
            var base64Url = token.split('.')[1];
            //var base64 = base64Url.replace('-', '+').replace('_', '/');
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } catch (e) {
            return null;
        }
    }

    static validToken(token: string): boolean {
        const jwtToken = Utils.parseJwt(token);
        if (jwtToken !== null && !isNaN(jwtToken.exp)) {
            const date = new Date;
            const dateNow = date.getTime();
            const unixTimeNow = Math.floor(dateNow / 1000);
            return jwtToken.exp > unixTimeNow;
        }
        return false;
    }

    static getCustomerIdFromAccessToken() {
        const customerBearerToken = localStorage.getItem('token');
        if (customerBearerToken !== null) {
            const customerAccessToken = this.parseJwt(customerBearerToken);
            return customerAccessToken.sub;
        }
        return null;
    }

    static getLocationFromPostalCode(postalCode: string) {
        return new Promise((resolve, reject) => {
            return fetch('https://adressesok.posten.no/api/v1/postal_codes.json?postal_code=' + postalCode, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json'
                })
            }).then(function (response: Response) {
                return response.json();
            }).then((json: any) => {
                if (json.postal_codes !== undefined) {
                    let city = json.postal_codes[0].city;
                    let firstLetter = city.slice(0, 1);
                    city = firstLetter.toUpperCase() + city.substring(1).toLowerCase();
                    let response = '';
                    if (city !== '') {
                        response = city;
                    }
                    resolve(response);
                } else {
                    resolve('');
                }
                return json;
            }).catch((ex: JSON) => {
                reject(new Error('Kunne ikke hente sted fra postnummer. ' + JSON.stringify(ex)));
            });
        });
    }

    static getCompanyInfoFromOrgNr(organizationNumber: string) {
        return new Promise((resolve, reject) => {
            return fetch('https://hotell.difi.no/api/json/brreg/enhetsregisteret?query=' + encodeURIComponent(organizationNumber), {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json'
                })
            }).then(function (response: Response) {
                return response.json();
            }).then((json: any) => {
                let companyInfo: any = {
                    'bussiness_name': '',
                    'address_line': '',
                    'postal_code': '',
                    'postal_place': '',
                    'industry': '',
                    'number_of_employees': '',
                };
                if (json.entries !== undefined && json.entries.length > 0) {
                    if (json.entries[0].navn !== undefined) {
                        companyInfo.bussiness_name = json.entries[0].navn;
                    }
                    if (json.entries[0].forretningsadr !== undefined) {
                        companyInfo.address_line = json.entries[0].forretningsadr;
                    }
                    if (json.entries[0].forradrpostnr !== undefined) {
                        companyInfo.postal_code = json.entries[0].forradrpostnr;
                    }
                    if (json.entries[0].forradrpoststed !== undefined) {
                        companyInfo.postal_place = json.entries[0].forradrpoststed;
                    }
                    if (json.entries[0].nkode1 !== undefined) {
                        companyInfo.industry = json.entries[0].nkode1;
                    }
                    if (json.entries[0].ansatte_antall !== undefined) {
                        companyInfo.number_of_employees = json.entries[0].ansatte_antall;
                    }
                }
                resolve(companyInfo);
                return json;
            }).catch((ex: JSON) => {
                reject(new Error('Kunne ikke hente firmainfo fra orgnr. ' + JSON.stringify(ex)));
            });
        });
    }

    static formatOrgnummer(orgNummer: string) {
        if (!orgNummer) {
            return '';
        }
        let nummer: string = orgNummer.substr(0, 9);
        let extra: string = '';
        if (orgNummer.length > 9) {
            extra = ' ' + orgNummer.substr(9, orgNummer.length);
        }
        return 'Org ' + nummer.replace(/\B(?=(.{3})+(?!.))/g, '\u00A0') +
            extra;
    }

    static formatPrice(price: number) {
        if (isNaN(price)) {
            return '';
        }
        let p: string = (price / 100).toFixed(2);
        let parts = p.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
        return parts.join(',');
    }


    static formatTimestamp(isoDate: string) {
        let ts = isoDate.split(/((\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\w+)?)/);
        return ts[4] + '.' + ts[3] + '.' + ts[2] + ' ' + ts[5] + ':' + ts[6];
    }

    static generateEan13(receiptId: string) {
        if (!receiptId) {
            return false;
        }

        let code: string = '200' + this.padEnd(receiptId, '0', 9);

        let weightFlag: boolean = true;
        let sum: number = 0;
        for (let i = code.length - 1; i >= 0; i--) {
            sum += Number(code.substr(i, 1)) * (weightFlag ? 3 : 1);
            weightFlag = !weightFlag;
        }
        code += ((10 - (sum % 10)) % 10);
        return code;
    }

    static padEnd(source: string, padString: string, length: number) {
        while (source.length < length) {
            source = padString + source;
        }
        return source;
    }

    static getBase64Image(img: any) {
        let canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        let ctx = canvas.getContext('2d');
        if (ctx !== null) {
            ctx.drawImage(img, 0, 0);
        }
        var dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }

    static getAccessToken = (config: any, userConfig: any): Promise<string | null> => {
        const bearerToken = sessionStorage.getItem('access_token');
        if (bearerToken !== null && Utils.validToken(bearerToken)) {
            return Promise.resolve(bearerToken);
        }

        const encodedString = new Buffer(
            userConfig.API_KEY + ':' + userConfig.API_SECRET
        ).toString('base64');

        return fetch(
            config.urlApi + 'accounts/' + userConfig.AID + '/auth/token', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Basic ' + encodedString,
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    audience: config.urlApi + 'accounts/' + userConfig.AID,
                    grant_type: 'client_credentials',
                    verification_code: 'string'
                })
            }).then(function (response: Response) {
            return response.json();
        }).then((json: any) => {
            sessionStorage.setItem('access_token', json.access_token);
            return sessionStorage.getItem('access_token');
        }).catch(function (ex: JSON) {
            console.log('parsing failed', ex);
            return null;
        });
    }

    static fetchWithAccessToken(url: string, init: RequestInit, config: any, userConfig: any): Promise<Response> {
        return this.getAccessToken(config, userConfig).then(accessToken => {
            if (!accessToken) {
                return Promise.reject('Failed to get access token');
            }
            init.headers = new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            });
            return fetch(url, init);
        });
    }

    static displayErrorAlert(message: string) {
        const existingErrorAlert = document.getElementById('error-alert');
        if (existingErrorAlert != null) {
            existingErrorAlert.innerHTML = message;
            existingErrorAlert.style.display = 'block';
        }

        setTimeout(() => {
            const existingErrorAlert = document.getElementById('error-alert');
            if (existingErrorAlert != null) {
                existingErrorAlert.style.display = 'none';
            }
        }, 6000);
    }

    static fetchGenericError(json: any) {
        if (json.status !== undefined && json.status === -1) {
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            document.location.href = '';
        } else {
            const errorMessage = (json.message !== undefined) ? ' ' + json.message : '';
            this.displayErrorAlert(i18next.t('errors.generic') + '' + errorMessage);
        }
    }

    static fetchCatch(exeption: any) {
        if (String(exeption).indexOf("NetworkError") >= 0) {
            this.displayErrorAlert(i18next.t('errors.offline'));
        } else if (exeption.status !== undefined && exeption.status === -1) {
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            document.location.href = '';
        } else {
            this.displayErrorAlert(i18next.t('errors.generic') + ' ' + exeption);
        }
    }

    static getApiUrl() {
        const configApiDev = localStorage.getItem('config_api_dev');
        return (configApiDev !== null && configApiDev == '1') ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    }


    static logout(api: string = '') {
        if (!localStorage.getItem('organizer_id') || !localStorage.getItem('event_id')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('name');
            localStorage.removeItem('organizer_id');
            localStorage.removeItem('organizer_name');
            localStorage.removeItem('event_id');
            localStorage.removeItem('event_name');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('event_entry_point_id');
            localStorage.removeItem('event_entry_point_name');
            localStorage.removeItem('event_entry_points');
            localStorage.removeItem('event_recurring');
            document.location.href = '';
        } else {
            // @ts-ignore
            this.logoutFetch(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('token'), api);
        }
    }

    static logoutFetch(user_id: number, organizer_id: number, token: string, api: string = '') {
        let apiUrl = Utils.getApiUrl();
        if (api == 'dev') {
            apiUrl = process.env.REACT_APP_API_URL_DEV;
        } else if (api == 'prod') {
            apiUrl = process.env.REACT_APP_API_URL_PROD;
        }

        fetch(
            apiUrl + '/user/' + user_id + '/organizer/' + organizer_id + '/logout',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.logout !== undefined) {
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('name');
                localStorage.removeItem('organizer_id');
                localStorage.removeItem('organizer_name');
                localStorage.removeItem('event_id');
                localStorage.removeItem('event_name');
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('event_entry_point_id');
                localStorage.removeItem('event_entry_point_name');
                localStorage.removeItem('event_entry_points');
                document.location.href = '';
            } else {
                Utils.fetchGenericError(json);
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }
}

export default Utils;
