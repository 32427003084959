import * as React from 'react';
import {Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link, Redirect, Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import PropTypes from "prop-types";
//import {setIsAuthenticatedT} from "../actions/actions";
//import {ActionType} from '../actions/actions';
import {History} from "history";
import i18next from '../language/translation';
import Utils from "../utils/Utils";

//const logo = require('../images/logo.png');

interface State {
    isAuthenticated: boolean;
    user_id: number;
    organizer_id: number;
    event_id: string;
    token: string;
    language: string;
    isMenuOpen: boolean;
    event_entry_point_id: string;
}

interface Props {
    history: History;
    isAuthenticated: boolean;
    //setIsAuthenticated: setIsAuthenticatedT;
    user_id: number;
    organizer_id: number;
    event_id: string;
    token: string;
    language: string;
    event_entry_point_id: string;
}


class Header extends React.Component <Props, State> {

    static contextTypes = {
        router: PropTypes.object
    };

    constructor(Props: any, context: any) {
        super(Props, context);
        this.state = {
            isAuthenticated: Props.isAuthenticated,
            user_id: 0,
            organizer_id: 0,
            event_id: '',
            token: '',
            language: localStorage.getItem('language') || 'no',
            isMenuOpen: false,
            event_entry_point_id: '',
        };

        this.logoutSubmit = this.logoutSubmit.bind(this);
    }


    public render() {
        const columnScanner = (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1');
        return (
            <Navbar color={columnScanner ? "dark" : "light"} dark={columnScanner} light={!columnScanner} expand="xxl">
                <NavbarBrand href=""><img
                    src={columnScanner ? "logo-white.png" : "logo.png"}
                    alt="Fticket"/></NavbarBrand>
                {this.state.language != 'no' &&
                    <Button onClick={() => this.changeLanguage('no')} color="link"
                            className="float-left language-btn">no</Button>}
                {this.state.language != 'en' &&
                    <Button onClick={() => this.changeLanguage('en')} color="link"
                            className="float-left language-btn">en</Button>}
                <NavbarToggler onClick={this.toggleMenu}/>
                <Collapse isOpen={this.state.isMenuOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        {localStorage.getItem('token') && localStorage.getItem('event_name') &&
                            <NavItem>
                                <NavLink href="#/user/organizer/select_event">
                                    {localStorage.getItem('event_name')}{localStorage.getItem('event_entry_point_name') && ' / ' }{localStorage.getItem('event_entry_point_name')} - {i18next.t('menu.change_event')}
                                </NavLink>
                            </NavItem>}
                        <NavItem>
                            <NavLink href="#/config">
                                {i18next.t('menu.config')}
                            </NavLink>
                        </NavItem>
                        {localStorage.getItem('token') &&
                            <NavItem>
                                <NavLink style={{cursor: 'pointer'}}
                                         onClick={this.logoutSubmit}>{i18next.t('menu.log_out')}</NavLink>
                            </NavItem>}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }


    changeLanguage = (language: string) => {
        localStorage.setItem('language', language);
        this.setState({'language': language});
        i18next.changeLanguage(language);
        document.location.reload();
    };

    toggleMenu = () => {
        this.setState({'isMenuOpen': !this.state.isMenuOpen});

        const lazerScannerTabPaneActive = document.getElementsByClassName('tab-pane-3 active');
        if (this.state.isMenuOpen && lazerScannerTabPaneActive !== undefined && lazerScannerTabPaneActive !== null && lazerScannerTabPaneActive.length > 0) {
            document.location.href = '';
        }
    };

    componentWillMount(): void {
        let language = localStorage.getItem('language');
        if (language === null || language === undefined || language === '') {
            language = 'no';
            this.setState({'language': language});
            localStorage.setItem('language', language);

        }
        if (language !== null) {
            i18next.changeLanguage(language);
        }

        let token = localStorage.getItem('token');
        let user_id = localStorage.getItem('user_id');
        let organizer_id = localStorage.getItem('organizer_id');
        if (token && user_id && organizer_id) {
            this.tokenRefresh(token);
        }

        let event_id = localStorage.getItem('event_id');
        if (event_id !== undefined && event_id !== null) {
            this.setState({'event_id': event_id});
        }
        setInterval(() => {
            if (localStorage.getItem('event_id') !== this.state.event_id && this.state.event_id !== undefined && this.state.event_id !== null) {
                let event_id = localStorage.getItem('event_id');
                if (event_id !== undefined && event_id !== null) {
                    this.setState({'event_id': event_id});
                    if (this.state.isMenuOpen) {
                        this.setState({'isMenuOpen': false});
                    }
                }
            }
            if (localStorage.getItem('event_entry_point_id') !== this.state.event_entry_point_id && this.state.event_entry_point_id !== undefined && this.state.event_entry_point_id !== null) {
                let event_entry_point_id = localStorage.getItem('event_entry_point_id');
                if (event_entry_point_id !== undefined && event_entry_point_id !== null) {
                    this.setState({'event_entry_point_id': event_entry_point_id});
                    if (this.state.isMenuOpen) {
                        this.setState({'isMenuOpen': false});
                    }
                }
            }
        }, 1000);
    }


    tokenRefresh(token: string) {
        if (token !== undefined && token !== null) {
            let decryptedToken = Utils.parseJwt(token);
            if (decryptedToken !== null) {
                let tokenExpireDate = decryptedToken.exp;
                let dateNow = new Date;
                let unixTimestampNow = Math.floor(dateNow.getTime() / 1000);
                let tokenExpireCountdown = tokenExpireDate - unixTimestampNow;
                tokenExpireCountdown = tokenExpireCountdown - (10 * 60);
                tokenExpireCountdown = tokenExpireCountdown * 1000;
                if (tokenExpireCountdown > 2147483647) {
                    tokenExpireCountdown = 2147483647; // Integer max
                }

                setTimeout(() => {
                    this.fetchNewToken(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('token'));
                }, tokenExpireCountdown);
            }
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('isLoggedIn');
            document.location.href = '';
        }
    }

    private fetchNewToken(user_id: any, organizer_id: any, token: any) {
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/token/refresh',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.token !== undefined) {
                localStorage.setItem('token', json.token);
            } else {
                Utils.fetchGenericError(json);
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }


    logoutSubmit(event: any) {
        event.preventDefault();
        if (window.confirm(i18next.t('user.logout_confirm'))) {
            Utils.logout();
        }
    }

}

/*const mapStateToProps = (state: State) => {
    return {
        isAuthenticated: state.isAuthenticated,
    };
};

export const Header = withRouter(connect(mapStateToProps)(HeaderComponent));
const Header = compose(
    withRouter,
    connect(mapStateToProps)
)(HeaderComponent);*/

export default Header;
//export const Header =  withRouter(HeaderComponent);
//export default withRouter<State, Props>(Header);