import {combineReducers} from "redux";

const userLogin = (user: any, action: any) => {
    if (action.type === 'USER_LOGIN') {
        return [...user, action.payload];
    }
    return [];
};

const selectOrganizer = (organizer: any, action: any) => {
    if (action.type === 'SELECT_ORGANIZER') {
        return [...organizer, action.payload];
    }
    return [];
};

const selectEvent = (event: any, action: any) => {
    if (action.type === 'SELECT_EVENT') {
        return [...event, action.payload];
    }
    return [];
};

export default combineReducers({
    userLogin,
    selectOrganizer,
    selectEvent
})