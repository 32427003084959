import * as React from 'react';
import i18next from "../language/translation";
import {Button} from "reactstrap";
import {History} from 'history';

interface State {
    eventEntryPoints: [];
}

interface Props {
    history: History;
}

class SelectEventEntryPoint extends React.Component <Props, State> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            eventEntryPoints: []
        };

        this.selectEventEntryPointClick = this.selectEventEntryPointClick.bind(this);
    }

    componentWillMount() {
        let eventEntryPoints = localStorage.getItem("event_entry_points");
        if (eventEntryPoints != null) {
            this.setState({
                eventEntryPoints: JSON.parse(eventEntryPoints)
            });
        }
    }

    selectEventEntryPointClick(eventEntryPointId: number, name: string) {
        localStorage.setItem('event_entry_point_id', String(eventEntryPointId));
        localStorage.setItem('event_entry_point_name', name);
        this.props.history.push("/user/organizer/event/ticket");
    }

    public render() {
        const {eventEntryPoints} = this.state;
        return (
            <React.Fragment>
                <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                    <h1 className="col-md-6 offset-md-3 pl-0 pt-4 select-event-title">
                        {i18next.t('event_entry_point.select_event_entry_point.title')}
                    </h1>
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group">
                        {eventEntryPoints && eventEntryPoints.map((event_entry_point: any) => (
                            <Button key={event_entry_point.event_entry_point_id}
                                    className="list-group-item list-group-item-action organizer"
                                    value={event_entry_point.event_entry_point_id}
                                    onClick={() => this.selectEventEntryPointClick(event_entry_point.event_entry_point_id, event_entry_point.name)}>{event_entry_point.name}
                            </Button>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SelectEventEntryPoint;