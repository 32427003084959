import * as React from 'react';
import {History} from 'history';
import LoadingSpinner from "./LoadingSpinner";
import i18next from '../language/translation';
import {Alert, Button, Form, FormGroup, Input, Label} from "reactstrap";
import Utils from "../utils/Utils";

interface State {
    isLoaded: boolean;
    logoutAfterSave: boolean;
    errorMessage: string;
    successMessage: string;
    lazer_scanner: boolean;
    column_scanner: boolean;
    api_dev: boolean;
}

interface Props {
    history: History;
}

class Config extends React.Component <Props, State> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            isLoaded: false,
            logoutAfterSave: false,
            errorMessage: '',
            successMessage: '',
            lazer_scanner: false,
            column_scanner: false,
            api_dev: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.configSubmit = this.configSubmit.bind(this);
    }

    public render() {
        const {errorMessage, isLoaded} = this.state;
        if (errorMessage) {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px", marginTop: "15px"}}>
                        <div className="card-box col-md-6 offset-md-3">
                            {errorMessage}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (!isLoaded) {
            return (
                <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group pt-4">
                    <LoadingSpinner/>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px"}} className="config-container">
                        <h1 className="col-md-6 offset-md-3 pl-0 pt-4">
                            {i18next.t('menu.config')}
                        </h1>
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group">
                            <Form action="#" method="post" id="config_form" onSubmit={this.configSubmit}>

                                <FormGroup check>
                                    <Input type="checkbox"
                                           className=""
                                           name="lazer_scanner"
                                           id="lazer_scanner"
                                           value="1"
                                           checked={this.state.lazer_scanner}
                                           onChange={this.handleInputChange}/>
                                    <Label htmlFor="lazer_scanner" check>{i18next.t('config.lazer_scanner')}</Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input type="checkbox"
                                           className=""
                                           name="column_scanner"
                                           id="column_scanner"
                                           value="1"
                                           checked={this.state.column_scanner}
                                           onChange={this.handleInputChange}/>
                                    <Label htmlFor="column_scanner" check>{i18next.t('config.column_scanner')}</Label>
                                </FormGroup>

                                <FormGroup check>
                                    <Input type="checkbox"
                                           className=""
                                           name="api_dev"
                                           id="api_dev"
                                           logout_required="1"
                                           value="1"
                                           checked={this.state.api_dev}
                                           onChange={this.handleInputChange}/>
                                    <Label htmlFor="api_dev" check>{i18next.t('config.api_dev')}</Label>
                                </FormGroup>

                                <FormGroup id="div_config_button" className={"mt-2"}>
                                    {this.state.errorMessage !== '' &&
                                        <Alert className="alert-danger alert-dismissible"
                                               toggle={this.handleErrorAlertDismiss}>
                                            {this.state.errorMessage}
                                        </Alert>}
                                    {this.state.successMessage !== '' &&
                                        <Alert className="alert-success alert-dismissible"
                                               toggle={this.handleSuccessAlertDismiss}>
                                            {this.state.successMessage}
                                        </Alert>}
                                    <Button type="submit" color="primary"
                                            id="config_submit_button">{i18next.t('config.save')}
                                    </Button>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    componentWillMount() {
        this.getConfigOptions();
    }

    private getConfigOptions() {
        const configLazerScanner = localStorage.getItem('config_lazer_scanner');
        const configColumnScanner = localStorage.getItem('config_column_scanner');
        const configApiDev = localStorage.getItem('config_api_dev');

        this.setState({
            lazer_scanner: (configLazerScanner !== null && configLazerScanner == '1'),
            column_scanner: (configColumnScanner !== null && configColumnScanner == '1'),
            api_dev: (configApiDev !== null && configApiDev == '1'),
            isLoaded: true,
        })
    }

    handleErrorAlertDismiss = () => {
        this.setState({errorMessage: ''});
    };

    handleSuccessAlertDismiss = () => {
        this.setState({successMessage: ''});
    };

    handleInputChange(event: any) {
        this.setState({errorMessage: ''});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.attributes.getNamedItem('logout_required') !== null && target.attributes.getNamedItem('logout_required') !== undefined && target.attributes.getNamedItem('logout_required').value == '1' && localStorage.getItem('token') !== null) {
            this.setState({logoutAfterSave: true});
        }

        // @ts-ignore
        this.setState({
            [name]: value
        });

        if (target.name == 'column_scanner' && value) {
            // @ts-ignore
            this.setState({
                ['lazer_scanner']: false
            });
        } else if (target.name == 'lazer_scanner' && value) {
            // @ts-ignore
            this.setState({
                ['column_scanner']: false
            });
        }

        if (target.name == 'api_dev' && localStorage.getItem('token') !== null) {
            localStorage.setItem('config_api_dev', (value) ? '1' : '0');
            Utils.logout((value) ? 'prod' : 'dev');
        }
    }

    configSubmit(event: any) {
        event.preventDefault();

        localStorage.setItem('config_lazer_scanner', (this.state.lazer_scanner) ? '1' : '0');
        localStorage.setItem('config_column_scanner', (this.state.column_scanner) ? '1' : '0');
        localStorage.setItem('config_api_dev', (this.state.api_dev) ? '1' : '0');

        this.setState({successMessage: i18next.t('config.save_success')})

        if (this.state.logoutAfterSave) {
            Utils.logout();
        }
    }


}

export default Config;