import React from 'react';
import i18next from '../language/translation';

interface State {
    name: string | null,
    language: string | null
}

class Footer extends React.Component <{}, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            name: localStorage.getItem('name'),
            language: localStorage.getItem('language')
        };
    }

    render() {
        return (
            <div className="text-center pt-2 footer">
                {/*{this.state.name &&
                <span>{i18next.t('user.logged_in_as')} {sessionStorage.getItem('name')}</span>}*/}
            </div>
        )

    }

}

export default Footer;