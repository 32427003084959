import * as React from 'react';
import {Form, Input, FormGroup, Label, Alert, Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {History} from 'history';
import {userLogin} from "../actions/actions";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Redirect} from "react-router";
import {connect} from "react-redux";

import i18next from '../language/translation';
import Utils from "../utils/Utils";

interface State {
    email: string;
    password: string;
    errorMessage: string;
    isAuthenticated: boolean;
    name: string;
    language: string;
    isLoggedIn: boolean;
}

interface Props {
    history: History;
    isAuthenticated: boolean;
}


class Login extends React.Component <Props, State> {

    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            email: '',
            password: '',
            errorMessage: '',
            isAuthenticated: false,
            name: '',
            language: 'no',
            isLoggedIn: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.loginSubmit = this.loginSubmit.bind(this);

    }

    public render() {
        const {errorMessage} = this.state;
        return (
            <React.Fragment>
                <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                    <h1 className="col-md-6 offset-md-3 pl-0 pt-4">
                        {i18next.t('user.login_form.title')}
                    </h1>
                    <div className="card-box col-md-6 offset-md-3">

                        <Form action="#" method="post" id="user_login_form" onSubmit={this.loginSubmit}>

                            <FormGroup>
                                <Label htmlFor="email">{i18next.t('user.login_form.email')}</Label>
                                <Input type="email"
                                       className="form-control"
                                       name="email"
                                       placeholder={i18next.t('user.login_form.email_placeholder')}
                                       required={true}
                                       value={this.state.email}
                                       onChange={this.handleInputChange}/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="password">{i18next.t('user.login_form.password')}</Label>
                                <Input type="password"
                                       className="form-control"
                                       name="password"
                                       required={true}
                                       value={this.state.password}
                                       onChange={this.handleInputChange}/>
                            </FormGroup>

                            <FormGroup id="div_user_login_button">
                                {this.state.errorMessage !== '' &&
                                <Alert className="alert-danger alert-dismissible"
                                       onDismiss={this.handleErrorAlertDismiss}>
                                    {this.state.errorMessage}
                                </Alert>}
                                <Button type="submit" color="primary"
                                        id="user_login_button">{i18next.t('user.login_form.button')}
                                </Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </React.Fragment>

        );
    }

    componentDidMount(): void {
        let localStorageToken = localStorage.getItem('token');
        let localStorageUserId = localStorage.getItem('user_id');
        let localStorageName = localStorage.getItem('name');
        let localStorageOrganizerId = localStorage.getItem('organizer_id');
        let localStorageOrganizerName = localStorage.getItem('organizer_name');
        if (localStorageToken
            && localStorageUserId
            && localStorageName
            && localStorageOrganizerId
            && localStorageOrganizerName) {
            localStorage.setItem('isLoggedIn', 'true');
            let localStorageEventId = localStorage.getItem('event_id');
            let localStorageEventName = localStorage.getItem('event_name');
            if (localStorageEventId
                && localStorageEventName) {
                this.props.history.push("/user/organizer/event/ticket");
            } else {
                this.props.history.push("/user/organizer/select_event");
            }
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('name');
            localStorage.removeItem('organizer_id');
            localStorage.removeItem('organizer_name');
            localStorage.removeItem('event_id');
            localStorage.removeItem('event_name');
            localStorage.removeItem('isLoggedIn');
        }
    }

    handleErrorAlertDismiss = () => {
        this.setState({errorMessage: ''});
    };

    handleInputChange(event: any) {
        this.setState({errorMessage: ''});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    loginSubmit(event: any) {
        this.login(this.state.email, this.state.password);
        event.preventDefault();
    }

    private login(email: string, password: string) {
        fetch(
            Utils.getApiUrl() + '/login',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    email: email,
                    password: password,
                })

            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.token !== undefined) {
                localStorage.setItem('token', json.token);
                localStorage.setItem('user_id', json.user_id);
                localStorage.setItem('name', json.name);
                this.setState({name: json.name});
                //store.dispatch(userLogin(json.name,json.user_id, json.token));
                this.props.history.push("user/select_organizer");
                //console.log(store.getState());
            } else {
                this.setState({errorMessage: json.error});
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }


}


const mapStateToProps = (state: State) => {
    return {userLogin: state.name};
};
/*const mapDispatchToProps = (dispatch: any) => {
    return {
        userLogin: (email: string, user_id: number, token: string) => dispatch(userLogin(email, user_id, token))
    };
};*/

/*const Login = compose(
    withRouter,
    connect(mapStateToProps)
)(LoginComponent);*/

//export default connect(mapStateToProps)(LoginComponent);

//export default Login as React.ComponentType<Props>;
export default Login;
