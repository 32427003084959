import * as React from 'react';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input, Label,
    Modal,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import PropTypes from "prop-types";
import {History} from "history";
import 'material-icons/iconfont/material-icons.scss';
import ReactDOM from "react-dom";
import i18next from "../../language/translation";
import Utils from "../../utils/Utils";

//import TicketDetail from "./TicketDetail";


interface State {
    tickets: [];
    isLoaded: boolean;
    search_string: string;
    scannerQrQuery: string;
    errorMessage: string;
    errorAlert: string;
    modal: boolean;
    modalMessage: string;
    modalBgColor: string;
    ticketValidationStatus: number;
    ticketAdmittingDate: string;
    activeTab: string;
    isScanningForQrCode: boolean;
    lazerScannerActive: boolean;
    columnScannerActive: boolean;
    validationResponseHideTimer: number;
    fetchAllFutureTickets: number;
    ticketsAdmitted: number;
    ticketsSold: number;
    ticketsTotal: number;
    keyFiguresInterval: number;

}

interface Props {
    history: History;
}

class TicketValidation extends React.Component <Props, State> {

    static contextTypes = {
        router: PropTypes.object
    };
    private myRef: any;
    private lazerScannerDivRef: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.myRef = React.createRef();
        this.lazerScannerDivRef = React.createRef();
        this.state = {
            search_string: '',
            scannerQrQuery: '',
            isLoaded: false,
            tickets: [],
            errorMessage: '',
            errorAlert: 'alert-danger alert-dismissible',
            modal: false,
            modalMessage: '',
            modalBgColor: '#007f15',
            ticketValidationStatus: 0,
            ticketAdmittingDate: '',
            activeTab: process.env.REACT_APP_PLATTFORM == 'web' ? ((localStorage.getItem('config_lazer_scanner') !== null && localStorage.getItem('config_lazer_scanner') == '1') || (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1')) ? '3' : '2' : ((localStorage.getItem('config_lazer_scanner') !== null && localStorage.getItem('config_lazer_scanner') == '1') || (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1')) ? '3' : '1',
            isScanningForQrCode: true,
            lazerScannerActive: (localStorage.getItem('config_lazer_scanner') !== null && localStorage.getItem('config_lazer_scanner') == '1'),
            columnScannerActive: (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1'),
            validationResponseHideTimer: 0,
            fetchAllFutureTickets: 0,
            ticketsAdmitted: 0,
            ticketsSold: 0,
            ticketsTotal: 0,
            keyFiguresInterval: 0
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitForColumnScanner = this.handleSubmitForColumnScanner.bind(this);
        this.handleInputChangeForLaserScanner = this.handleInputChangeForLaserScanner.bind(this);
        this.handleInputBlurForLaserScanner = this.handleInputBlurForLaserScanner.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.ticketSearchSubmit = this.ticketSearchSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.showCameraAndScanQrCode = this.showCameraAndScanQrCode.bind(this);
        this.hideCameraAndStopScanningForQrCode = this.hideCameraAndStopScanningForQrCode.bind(this);
        this.ValidateTicket = this.ValidateTicket.bind(this);
        this.getKeyFigures = this.getKeyFigures.bind(this);
    }

    public render() {
        const {errorMessage, errorAlert, tickets, activeTab} = this.state;

        let searchInputProps = {}
        let scannerQrQueryInputProps = {}

        if ((process.env.REACT_APP_PLATTFORM == 'web' && activeTab !== '3') || activeTab === '2') {
            // @ts-ignore
            searchInputProps.autoFocus = true;
        } else if ((this.state.lazerScannerActive || this.state.columnScannerActive) && activeTab === '3') {
            // @ts-ignore
            scannerQrQueryInputProps.autoFocus = true;
        }

        let testProps = {}
        // @ts-ignore
        testProps.autoFocus = true;

        // @ts-ignore
        return (
            <React.Fragment>
                <div className="col-12 m-0 p-0"
                     style={{backgroundColor: "#ebebeb"}}>
                    <div className="col-12 m-0 p-0"
                         style={{backgroundColor: "#ebebeb"}}>
                        <Nav className={"nav-pills nav-justified " + (this.state.columnScannerActive ? "d-none" : "")}>
                            {(this.state.lazerScannerActive || this.state.columnScannerActive) &&
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '3' ? 'active list-group-item list-group-item-action organizer btn btn-secondary' : 'list-group-item list-group-item-action organizer btn btn-secondary'}
                                        onClick={() => {
                                            this.toggleTab('3');
                                        }}>
                                        {i18next.t('ticket.tabs.scan')}
                                    </NavLink>
                                </NavItem>}
                            {(process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) &&
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '1' ? 'active list-group-item list-group-item-action organizer btn btn-secondary' : 'list-group-item list-group-item-action organizer btn btn-secondary'}
                                        onClick={() => {
                                            this.toggleTab('1');
                                        }}>
                                        {i18next.t('ticket.tabs.scan')}
                                    </NavLink>
                                </NavItem>}
                            <NavItem>
                                <NavLink
                                    className={(process.env.REACT_APP_PLATTFORM == 'web' && activeTab !== '3') || activeTab === '2' ? 'active list-group-item list-group-item-action organizer btn btn-secondary' : 'list-group-item list-group-item-action organizer btn btn-secondary'}
                                    onClick={() => {
                                        this.toggleTab('2');
                                    }}>
                                    {i18next.t('ticket.tabs.search')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                {!this.state.columnScannerActive &&
                    <div className="row m-0" style={{backgroundColor: "#FFFFFF"}} id="key-figures">
                        <div className="col-4 p-2 text-center" id="tickets-admitted">
                            {i18next.t('ticket.ticket_validate.tickets_admitted')}: {this.state.ticketsAdmitted}
                        </div>
                        <div className="col-4 p-2 text-center" id="tickets-sold">
                            {i18next.t('ticket.ticket_validate.tickets_sold')}: {this.state.ticketsSold}
                        </div>
                        <div className="col-4 p-2 text-center" id="tickets-total">
                            {i18next.t('ticket.ticket_validate.tickets_total')}: {this.state.ticketsTotal}
                        </div>
                    </div>
                }
                <div className="col-12 m-0 p-0 ">
                    <TabContent activeTab={activeTab} className="m-0 pt-0" style={{backgroundColor: "transparent"}}>
                        <TabPane tabId="1" className="m-0">
                            <div className="col-8 offset-2 mt-5 pt-5 qr-scanner-target"
                                 style={{position: "relative", backgroundColor: "transparent"}}>

                                <div className="qr-scanner-target-area">
                                    <div></div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2" className="m-0 p-0 search-result-container"
                                 style={{backgroundColor: "#EBEBEB"}}>
                            <div className="pt-2 m-0" style={{position: "relative", backgroundColor: "#EBEBEB"}}>
                                <Form action="#" method="post" onSubmit={this.ticketSearchSubmit}>
                                    <FormGroup className="col-12 mb-2">
                                        {errorMessage !== '' &&
                                            <Alert className={errorAlert}
                                                   onDismiss={this.handleErrorAlertDismiss}>
                                                {errorMessage}
                                            </Alert>}
                                        {(localStorage.getItem('event_recurring') == '1') &&
                                            <div className="btn-group btn-group-toggle mb-2" data-toggle="buttons">
                                                <Label
                                                    className={`btn btn-outline-primary btn-toggle ${this.state.fetchAllFutureTickets != 1 ? "active" : ""}`}>
                                                    <Input type="radio" name="fetchAllFutureTickets" id="option1"
                                                           autoComplete="off"
                                                           checked={this.state.fetchAllFutureTickets != 1}
                                                           onChange={this.handleInputChange}
                                                           value="0"/> {i18next.t('ticket.search_form.today')}
                                                </Label>
                                                <Label
                                                    className={`btn btn-outline-primary btn-toggle ${this.state.fetchAllFutureTickets == 1 ? "active" : ""}`}>
                                                    <Input type="radio" name="fetchAllFutureTickets" id="option2"
                                                           autoComplete="off"
                                                           checked={this.state.fetchAllFutureTickets == 1}
                                                           onChange={this.handleInputChange}
                                                           value="1"/> {i18next.t('ticket.search_form.from_today')}
                                                </Label>
                                            </div>
                                        }

                                        <div className="input-group">
                                            <Input style={{height: 'auto'}}
                                                   type="text"
                                                   className="form-control"
                                                   name="search_string"
                                                   placeholder={i18next.t('ticket.search_form.placeholder')}
                                                   value={this.state.search_string}
                                                   onChange={this.handleInputChange}
                                                   aria-describedby="button-addon"
                                                   autoFocus={true}
                                            />
                                            <div className="input-group-append" id="button-addon">
                                                <Button type="submit" color="primary"
                                                        id="ticket_search_button">
                                                    {i18next.t('ticket.search_form.button')}
                                                </Button>&nbsp;
                                                <Button type="reset"
                                                        color="light" onClick={() => {
                                                    this.formReset()
                                                }}><span className="material-icons">clear</span></Button>

                                            </div>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </div>
                            <div className="list-group search-result"
                                 style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                {tickets && tickets.map((ticket: any) => (
                                    /*<TicketDetail />*/
                                    <div key={ticket.ticket_reference_code}
                                         className="list-group-item list-group-item-action organizer mb-2">
                                        <div className="row">
                                            <div className="col-md-9 col-8">
                                                <div><strong>{ticket.name}</strong></div>
                                                <div>{ticket.email}</div>
                                                {ticket.phone_number &&
                                                    <div>
                                                        <strong>{i18next.t('ticket.search_result.phone')}:</strong> {ticket.phone_number}
                                                    </div>}
                                                {ticket.category_name &&
                                                    <div>
                                                        <strong>{i18next.t('ticket.search_result.ticket_category')}:</strong> {ticket.category_name}
                                                    </div>
                                                }
                                                <div>
                                                    <strong>{i18next.t('ticket.search_result.order_date')}:</strong> {this.date(ticket.order_date)}
                                                </div>
                                                {(localStorage.getItem('event_recurring') == '1' && ticket.subevent_start !== undefined) &&
                                                    <div>
                                                        <strong>{i18next.t('ticket.search_result.event_date')}:</strong> {ticket.subevent_start}
                                                        {(ticket.subevent_timeslot_start !== undefined && ticket.subevent_timeslot_start != '') &&
                                                            <span>
                                                                &nbsp; {ticket.subevent_timeslot_start}
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                                <div>
                                                    <strong>{i18next.t('ticket.search_result.ticket_reference')}:</strong> {ticket.ticket_reference_code}
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-4 d-flex align-items-center text-center"
                                                 id={ticket.ticket_reference_code}>
                                    <span className="align-middle">
                                        {ticket.status === 1 &&
                                            <Button key={ticket.ticket_reference_code}
                                                    color="primary"
                                                    className="float-right"
                                                    value={ticket.qr_code}
                                                    onClick={() => {
                                                        this.selectTicketClick(ticket.qr_code, ticket.ticket_reference_code)
                                                    }}>{i18next.t('ticket.search_result.ticket_validate_button')}
                                            </Button>}
                                        {ticket.status !== 1 &&
                                            <strong
                                                style={{color: '#9e0039'}}>{this.getFromStatusCode(ticket.status)}</strong>
                                        }
                                        {ticket.event_admitting_date &&
                                            <div>{this.date(ticket.event_admitting_date)}</div>}
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                    <ModalBody className="text-center pt-5"
                                               style={{backgroundColor: this.state.modalBgColor, color: 'white'}}>
                                        {this.state.ticketValidationStatus === 1 &&
                                            <span className="material-icons"
                                                  style={{fontSize: '6rem'}}>check_circle_outline</span>}
                                        {this.state.ticketValidationStatus !== 1 &&
                                            <span className="material-icons" style={{fontSize: '6rem'}}>clear</span>}
                                        {this.state.ticketValidationStatus === 0 && this.state.modalMessage == "" &&
                                            <div>{i18next.t('ticket.ticket_validate.no_match_for_ticket')}</div>}
                                        {this.state.ticketValidationStatus === 2 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_already_used')}</div>}
                                        {this.state.ticketAdmittingDate !== '' &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_used')} {this.date(this.state.ticketAdmittingDate)}</div>}
                                        {this.state.ticketValidationStatus === 3 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_deactivated')}</div>}
                                        {this.state.ticketValidationStatus === 4 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_refunded')}</div>}
                                        <div
                                            style={{marginTop: '1rem'}}>{this.state.modalMessage.split('\n').map((it, i) =>
                                            <div
                                                key={'x' + i}>{it}</div>)}</div>
                                    </ModalBody>
                                    <ModalFooter style={{backgroundColor: this.state.modalBgColor, border: 'none'}}>
                                        <Button color="light" onClick={this.toggle}>
                                            {i18next.t('ticket.ticket_validate.validate_popup_close_button')}
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </TabPane>
                        <TabPane tabId="3" className="m-0 tab-pane-3">
                            <div
                                className={this.state.columnScannerActive ? "col-10 offset-1" : "col-8 offset-2 mt-5 pt-5"}
                                style={{position: "relative"}}>
                                {this.state.columnScannerActive &&
                                    <div className="text-center" id="lazer_scanner_start_text">
                                        {i18next.t('ticket.column_scanner.start_text')}<br/>
                                        {i18next.t('ticket.column_scanner.start_text2')}<br/>
                                        <span className="material-icons mt-3">arrow_downward</span>
                                    </div>
                                }
                                {this.state.lazerScannerActive &&
                                    <div className="text-center" id="lazer_scanner_start_text"
                                         style={{fontSize: '1.5rem'}}>
                                        {i18next.t('ticket.lazer_scanner.start_text')}
                                    </div>
                                }
                                <Form action="#" method="post" onSubmit={this.handleSubmitForColumnScanner}>

                                    <Input
                                        type="text"
                                        name="scannerQrQuery"
                                        id="scannerQrQuery"
                                        onBlur={this.handleInputBlurForLaserScanner}
                                        ref={this.myRef}
                                        value={this.state.scannerQrQuery}
                                        onChange={this.handleInputChangeForLaserScanner}
                                        autoFocus={true}
                                        style={{position: 'absolute', left: '-10000px'}}/>
                                    <div id="validation-response" key="validation-response"
                                         style={{backgroundColor: this.state.modalBgColor, color: 'white'}}
                                         ref={this.lazerScannerDivRef}
                                         className="validation-response">
                                        {this.state.ticketValidationStatus === 1 &&
                                            <span className="material-icons"
                                                  style={{fontSize: '6rem'}}>check_circle_outline</span>}
                                        {this.state.ticketValidationStatus !== 1 &&
                                            <span className="material-icons" style={{fontSize: '6rem'}}>clear</span>}
                                        {(this.state.ticketValidationStatus === 0 && this.state.modalMessage == "" && !this.state.columnScannerActive) &&
                                            <div>{i18next.t('ticket.ticket_validate.no_match_for_ticket')}</div>}
                                        {(this.state.ticketValidationStatus === 0 && this.state.modalMessage == "" && this.state.columnScannerActive) &&
                                            <div>{i18next.t('ticket.ticket_validate.invalid_ticket')}</div>}
                                        {this.state.ticketValidationStatus === 2 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_already_used')}</div>}
                                        {this.state.ticketAdmittingDate !== '' &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_used')} {this.date(this.state.ticketAdmittingDate)}</div>}
                                        {this.state.ticketValidationStatus === 3 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_deactivated')}</div>}
                                        {this.state.ticketValidationStatus === 4 &&
                                            <div>{i18next.t('ticket.ticket_validate.ticket_refunded')}</div>}
                                        <div
                                            style={{marginTop: '1rem'}}>{this.state.modalMessage.split('\n').map((it, i) =>
                                            <div
                                                key={'x' + i}>{it}</div>)}</div>
                                    </div>
                                </Form>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </React.Fragment>
        );
    }

    handlePaste(e: any) {
        var clipboardData, pastedData;

        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        clipboardData = e.clipboardData;
        pastedData = clipboardData.getData('Text');

        // Do whatever with pasteddata
        this.ValidateTicket(
            localStorage.getItem('user_id'),
            localStorage.getItem('organizer_id'),
            localStorage.getItem('event_id'),
            localStorage.getItem('token'),
            pastedData, null, true);
    }

    componentDidMount(): any {
        const configLazerScanner = localStorage.getItem('config_lazer_scanner');
        if (configLazerScanner !== null && configLazerScanner == '1') {
            if (!this.state.lazerScannerActive) {
                this.setState({lazerScannerActive: true});
            }
            if (this.state.activeTab !== '3') {
                this.setState({activeTab: '3'});
            }
        }

        const configColumnScanner = localStorage.getItem('config_column_scanner');
        if (configColumnScanner !== null && configColumnScanner == '1') {
            if (!this.state.columnScannerActive) {
                this.setState({columnScannerActive: true});
            }

            if (this.state.activeTab !== '3') {
                this.setState({activeTab: '3'});
            }
        }

        if (process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) {
            setTimeout(() => {
                this.showCameraAndScanQrCode();
            }, 1000);

            let body = document.getElementById("body");
            if (body && !body.classList.contains("qr-scanner")) {
                body.classList.add("qr-scanner");
            }
        }

        this.getKeyFigures(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('event_id'), localStorage.getItem('token'));
        const keyFiguresIntervalNumber = window.setInterval(this.getKeyFigures, 300000, localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('event_id'), localStorage.getItem('token'));

        this.setState({
            keyFiguresInterval: keyFiguresIntervalNumber
        });
    }

    componentWillUnmount(): void {
        if (process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) {
            QRScanner.cancelScan();
            QRScanner.hide();
        }
        if (this.state.keyFiguresInterval > 0) {
            clearInterval(this.state.keyFiguresInterval);
        }
    }

    toggleTab(tab: any) {
        let body = document.getElementById("body");
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if (tab == 1) {
            this.setState({'isScanningForQrCode': true});
            if (body && !body.classList.contains("qr-scanner")) {
                body.classList.add("qr-scanner");
            }
            this.showCameraAndScanQrCode(true);
        }
        if (tab == 2) {
            if (body && body.classList.contains("qr-scanner")) {
                body.classList.remove("qr-scanner");
            }
            this.setState({'isScanningForQrCode': false});
            this.hideCameraAndStopScanningForQrCode();
        }
        if (tab == 3) {
            document.location.href = '';
        }
    }

    toggle() {
        if (this.state.modal) {
            this.setState({
                'modalMessage': ''
            });
        }
        if (process.env.REACT_APP_PLATTFORM == 'web' && this.state.modal) {
            this.setState({'search_string': ''});
        }
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
        if (!this.state.isScanningForQrCode) {
            setTimeout(() => {
                this.setState({'isScanningForQrCode': true});
                this.showCameraAndScanQrCode(true);
            }, 600);
        }
    }


    getFromStatusCode(statusCode: any) {
        if (statusCode === 0) {
            return i18next.t('ticket.search_result.no_match_for_ticket');
        }
        if (statusCode === 2) {
            return i18next.t('ticket.search_result.ticket_already_used');
        }
        if (statusCode === 3) {
            return i18next.t('ticket.search_result.ticket_deactivated');
        }
        if (statusCode === 4) {
            return i18next.t('ticket.search_result.ticket_refunded');
        }
    }

    date(dateString: any) {
        let date = new Date(dateString);
        return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear().toString().substr(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
    }

    formReset() {
        this.setState({
            search_string: '',
            tickets: [],
            errorMessage: '',
            errorAlert: 'alert-warning alert-dismissible',
        });
    }

    handleErrorAlertDismiss = () => {
        this.setState({errorMessage: ''});
        this.setState({modalMessage: ''});
        this.setState({errorAlert: 'alert-danger alert-dismissible'});
    };

    handleInputChange(event: any) {
        this.setState({errorMessage: ''});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    handleInputChangeForLaserScanner(event: any) {
        const value = event.target.value;

        if (this.state.lazerScannerActive) {
            if (value != '') {
                // @ts-ignore
                this.setState({
                    [event.target.name]: ''
                });

                this.ValidateTicket(
                    localStorage.getItem('user_id'),
                    localStorage.getItem('organizer_id'),
                    localStorage.getItem('event_id'),
                    localStorage.getItem('token'),
                    value, null, true);
            }
        } else {
            // @ts-ignore
            this.setState({
                [event.target.name]: value
            });
        }
    }

    handleSubmitForColumnScanner(event: any) {
        event.preventDefault();
        if (this.state.columnScannerActive) {
            const qr = this.state.scannerQrQuery;

            if (qr != '') {
                this.ValidateTicket(
                    localStorage.getItem('user_id'),
                    localStorage.getItem('organizer_id'),
                    localStorage.getItem('event_id'),
                    localStorage.getItem('token'),
                    qr, null, true, true);

                this.setState({'scannerQrQuery': ''});
            }
        }

    }

    handleInputBlurForLaserScanner(event: any) {
        if (!this.state.modal && (this.state.activeTab === '3' || this.state.columnScannerActive)) {
            setTimeout(() => {
                if (!this.state.modal && (this.state.activeTab === '3' || this.state.columnScannerActive) && document.getElementById('lazer_scanner_start_text') !== null) {
                    const navbar = document.getElementsByClassName('navbar-collapse show');
                    if (navbar.length === 0 || navbar.item(0) === undefined || navbar.item(0) === null) {
                        document.location.href = '';
                    }
                }
            }, 500);
        }
    }

    showCameraAndScanQrCode(isScanningForQrCode = false): any {
        let validateQrCode = (err: any, ticketQrCode: string) => {
            this.setState({'isScanningForQrCode': false});
            if (ticketQrCode) {
                let scannedTicketQrCode = ticketQrCode;
                //if (window.hasOwnProperty("cordova")) {
                if (process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) {
                    QRScanner.cancelScan();
                    QRScanner.hide();
                }
                //}
                this.ValidateTicket(
                    localStorage.getItem('user_id'),
                    localStorage.getItem('organizer_id'),
                    localStorage.getItem('event_id'),
                    localStorage.getItem('token'),
                    scannedTicketQrCode, null);
            }
        };
        if (isScanningForQrCode || this.state.isScanningForQrCode) {
            //if (window.hasOwnProperty("cordova")) {
            if (process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) {
                QRScanner.show();
                QRScanner.scan(validateQrCode);
            }
            //}
        }
    }

    hideCameraAndStopScanningForQrCode(): any {
        //if (window.hasOwnProperty("cordova")) {
        if (process.env.REACT_APP_PLATTFORM != 'web' && !this.state.lazerScannerActive && !this.state.columnScannerActive) {
            QRScanner.cancelScan();
            QRScanner.hide();
        }
        //}
    }

    ticketSearchSubmit(event: any) {
        event.preventDefault();
        this.setState({
            tickets: []
        });
        let searchString = this.state.search_string;
        if (searchString === '') {
            this.setState({errorAlert: 'alert-warning alert-dismissible'});
            this.setState({errorMessage: i18next.t('ticket.search_form.errors.empty_search_string')});
        } else {
            this.ticketSearch(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('event_id'), localStorage.getItem('token'), searchString);
        }
    }

    private ticketSearch(user_id: any, organizer_id: any, event_id: any, token: any, search_string: string) {
        let recurringEvent = (localStorage.getItem('event_recurring') == '1') ? 1 : 0;
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/event/' + event_id + '/ticket/search',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                }),
                body: JSON.stringify({
                    search_string: search_string,
                    recurring_event: recurringEvent,
                    fetch_all_future_tickets: this.state.fetchAllFutureTickets
                })

            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.tickets !== undefined) {
                this.setState({
                    tickets: json.tickets
                });
                if (json.tickets.length === 0) {
                    this.setState({errorAlert: 'alert-warning alert-dismissible'});
                    this.setState({errorMessage: i18next.t('ticket.search_form.errors.no_search_result')});
                }
            } else {
                Utils.fetchGenericError(json);
            }
        }).catch((ex: any) => {
            Utils.fetchCatch(ex);
        });
    }

    private getKeyFigures(user_id: any, organizer_id: any, event_id: any, token: any) {
        if (!this.state.columnScannerActive) {
            let recurringEvent = (localStorage.getItem('event_recurring') == '1') ? 1 : 0;
            fetch(
                Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/event/' + event_id + '/key_figures?recurring_event=' + recurringEvent,
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': token
                    })
                }).then((response: Response) => {
                return response.json();
            }).then((json: any) => {
                if (json.admitted_tickets !== undefined) {
                    this.setState({
                        ticketsAdmitted: json.admitted_tickets
                    });
                    if (json.tickets_sold !== undefined) {
                        this.setState({
                            ticketsSold: json.tickets_sold
                        });
                    }
                    if (json.tickets_total !== undefined) {
                        this.setState({
                            ticketsTotal: json.tickets_total
                        });
                    }
                } else {
                    Utils.fetchGenericError(json);
                }
            }).catch((ex: any) => {
                Utils.fetchCatch(ex);
            });
        }
    }

    selectTicketClick(ticketQrCode: string, ticketReferenceCode: string) {
        this.ValidateTicket(localStorage.getItem('user_id'),
            localStorage.getItem('organizer_id'),
            localStorage.getItem('event_id'),
            localStorage.getItem('token'),
            ticketQrCode,
            ticketReferenceCode);
    }


    private ValidateTicket(user_id: any, organizer_id: any, event_id: any, token: any, qr_code: any, ticket_reference_code: any = null, lazer_scanner: boolean = false, column_scanner: boolean = false) {
        let body: any = {
            qr_code: qr_code,
            language: localStorage.getItem('language'),
            scanner: 0
        }

        let eventEntryPointId = localStorage.getItem('event_entry_point_id');
        if (eventEntryPointId != undefined && eventEntryPointId != '' && !isNaN(parseInt(eventEntryPointId))) {
            body.event_entry_point_id = parseInt(eventEntryPointId);
        }

        if (column_scanner) {
            body.scanner = 2;
        } else if (lazer_scanner) {
            body.scanner = 1;
        }

        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/event/' + event_id + '/ticket/validate',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                }),
                body: JSON.stringify(body)
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.ticket_id !== undefined) {

                if (json.status === 1) {
                    this.getKeyFigures(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('event_id'), localStorage.getItem('token'));
                    this.setState({modalBgColor: '#007f15'});
                    if (ticket_reference_code) {
                        ReactDOM.render(<div><span className="align-middle">
                        <strong
                            style={{color: '#007f15'}}>{i18next.t('ticket.search_result.ticket_validate_ok')}</strong>
                    </span></div>, document.getElementById(ticket_reference_code));
                    }
                } else {
                    this.setState({modalBgColor: '#9e0039'});
                }

                if (json.event_admitting_date !== undefined && json.event_admitting_date !== null && json.event_admitting_date !== '') {
                    this.setState({ticketAdmittingDate: json.event_admitting_date});
                } else {
                    this.setState({ticketAdmittingDate: ''});
                }

                if (json.message !== undefined && json.message !== null && json.message !== '') {
                    this.setState({modalMessage: json.message});
                } else {
                    this.setState({modalMessage: ''});
                }
                this.setState({ticketValidationStatus: json.status});

                if (lazer_scanner) {
                    const columnScanner = (localStorage.getItem('config_column_scanner') !== null && localStorage.getItem('config_column_scanner') == '1');
                    const modalTime = (this.state.columnScannerActive) ? 3000 : 15000;
                    if (this.lazerScannerDivRef.current.style.display == 'block' || this.lazerScannerDivRef.current.style.display == 'flex') {
                        this.lazerScannerDivRef.current.style.opacity = '0%';
                        setTimeout(() => {
                            this.lazerScannerDivRef.current.style.opacity = '100%';
                        }, 200);
                    } else {
                        if (columnScanner) {
                            this.lazerScannerDivRef.current.style.display = 'flex';
                        } else {
                            this.lazerScannerDivRef.current.style.display = 'block';
                        }

                        let lazer_scanner_start_text = document.getElementById('lazer_scanner_start_text');
                        if (lazer_scanner_start_text !== null) {
                            lazer_scanner_start_text.style.display = 'none';
                        }
                    }

                    const validationResponseHideTimer = setTimeout(() => {
                        this.lazerScannerDivRef.current.style.display = 'none';
                        let lazer_scanner_start_text = document.getElementById('lazer_scanner_start_text');
                        if (lazer_scanner_start_text !== null) {
                            lazer_scanner_start_text.style.display = 'block';
                        }
                        if (document.activeElement != document.getElementById('scannerQrQuery')) {
                            document.location.href = '';
                        }
                    }, modalTime);
                    if (this.state.validationResponseHideTimer > 0) {
                        clearTimeout(this.state.validationResponseHideTimer);
                    }
                    // @ts-ignore
                    this.setState({validationResponseHideTimer});
                } else {
                    this.setState({modal: true});
                }

            } else {
                Utils.fetchGenericError(json);
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }

}

export default TicketValidation;