import * as React from 'react';
import {Button} from "reactstrap";
import {History} from 'history';
import LoadingSpinner from "./LoadingSpinner";
import {connect} from "react-redux";
import {selectEvent} from "../actions/actions";
import i18next from '../language/translation';
import Utils from "../utils/Utils";

interface State {
    events: [];
    isLoaded: boolean;
    errorMessage: string;
}

interface Props {
    history: History;
}

class SelectEvent extends React.Component <Props, State> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state = {
            errorMessage: '',
            isLoaded: false,
            events: []
        };

        this.selectEventClick = this.selectEventClick.bind(this);
    }

    public render() {
        const {errorMessage, isLoaded, events} = this.state;
        if (errorMessage) {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px", marginTop: "15px"}}>
                        <div className="card-box col-md-6 offset-md-3">
                            {errorMessage}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (!isLoaded) {
            return (
                <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group pt-4">
                    <LoadingSpinner/>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                        <h1 className="col-md-6 offset-md-3 pl-0 pt-4 select-event-title">
                            {i18next.t('event.select_event.title')}
                        </h1>
                        <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3 list-group">
                            {events && events.map((event: any) => (
                                <Button key={event.event_id}
                                        className="list-group-item list-group-item-action organizer"
                                        value={event.event_id}
                                        onClick={this.selectEventClick}>{event.event_name}
                                </Button>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    componentWillMount() {
        let body = document.getElementById("body");
        if (body && body.classList.contains("qr-scanner")) {
            body.classList.remove("qr-scanner");
        }
        this.getEvents(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), localStorage.getItem('token'));
    }

    private getEvents(user_id: any, organizer_id: any, token: any) {
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/events',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.events !== undefined) {
                this.setState({
                    isLoaded: true,
                    events: json.events
                });
            } else {
                this.setState({
                    isLoaded: true,
                    errorMessage: json.error
                });
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }

    selectEventClick(event: any) {
        event.preventDefault();
        let eventId = event.target.value;
        this.selectEvent(localStorage.getItem('user_id'), localStorage.getItem('organizer_id'), eventId, localStorage.getItem('token'));
    }

    private selectEvent(user_id: any, organizer_id: any, event_id: any, token: any) {
        fetch(
            Utils.getApiUrl() + '/user/' + user_id + '/organizer/' + organizer_id + '/event/' + event_id + '/select',
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': token
                })
            }).then((response: Response) => {
            return response.json();
        }).then((json: any) => {
            if (json.event_id !== undefined) {
                localStorage.setItem('event_id', json.event_id);
                let recurringEvent = (json.recurring !== undefined && json.recurring == 1) ? '1' : '0';
                localStorage.setItem('event_recurring', recurringEvent);
                localStorage.setItem('event_name', json.event_name);
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.removeItem('event_entry_point_id');
                localStorage.removeItem('event_entry_point_name');
                localStorage.removeItem('event_entry_points');
                //store.dispatch(selectEvent(json.event_name));
                if (json.event_entry_point !== undefined) {
                    if (json.event_entry_point.length == 1) {
                        localStorage.setItem('event_entry_point_id', json.event_entry_point[0].event_entry_point_id);
                        localStorage.setItem('event_entry_point_name', json.event_entry_point[0].name);
                        this.props.history.push("/user/organizer/event/ticket");
                    } else {
                        localStorage.setItem("event_entry_points", JSON.stringify(json.event_entry_point));
                        this.props.history.push("/user/organizer/event/select_event_entry_point");
                    }
                } else {
                    this.props.history.push("/user/organizer/event/ticket");
                }
            } else {
                this.setState({errorMessage: json.error});
            }
        }).catch((ex: JSON) => {
            Utils.fetchCatch(ex);
        });
    }


}

/*const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(SelectEvent);*/

export default SelectEvent;