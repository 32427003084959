import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/scss/bootstrap.scss';
import './css/index.scss';
import App from './components/App';
import Redux from 'redux';
import {createStore, combineReducers, applyMiddleware, Store} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/reducers';
import {Provider} from "react-redux";
import {userLogin, selectOrganizer, selectEvent} from "./actions/actions";

const store = createStore(rootReducer,  applyMiddleware(thunk));


/*const {createStore, combineReducers} = Redux;
const ourDepartments = combineReducers({
    userLogin:userLogin,
    selectOrganizer:selectOrganizer,
    selectEvent:selectEvent
});
const store = createStore(ourDepartments);*/


ReactDOM.render(<Provider store={store} key="provider">
    <App/>
</Provider>, document.getElementById('root'));



